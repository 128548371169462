import React from 'react'
import Layout from '../../components/Layout'
import styled from 'styled-components'
import Block from '../../components/Block'

const NoAuthPage = () => {
  return (
    <Layout
      justifyCenter
      title="Unauthenticated"
      description="No authentication found"
    >
      <Wrapper>
        <Block size="small">
          <h1>🤖 We couldn&#39;t log you in</h1>
          <p>
            Your team might not be using Swarmia yet. Please confirm you have
            the Swarmia app installed for your GitHub Enterprise organization.
            This is needed for each organization separately.
          </p>
          <p>
            Please <a href="mailto:hello@swarmia.com">let us know</a> if you are
            having trouble logging in.
          </p>
        </Block>
      </Wrapper>
    </Layout>
  )
}

export default NoAuthPage

const Wrapper = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px 20px 120px 20px;
`
